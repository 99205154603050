<template>
	<div id="app" class=" ">
		<div class="header">
			<div class="conter fled comm">
				<div class="left fls">
					<img class="logo" src="./assets/logo.png" alt="">
					<div class="menys" @click="chageishow">
						<img src="./assets/menu.png" alt="" />
					
					</div>
				</div>
			
				<div class="cont " :class=" ishow==false ? 'ishoe':''  " style="z-index: 99999999;">
					<ul>
					<!-- 	<li v-for="(item,index) in allheder" :key="index" :class=" item.name == '链接钱包' ?'active':'' "
							@click="chagetop(index)">
							<router-link :to="item.path "
								:class=" double== index  ?'actives':'' ">{{ item.name }}</router-link>
						</li> -->
						<li>
							<router-link to="/"  >{{$t('a1')}}</router-link>
						</li>
						<li>
							<router-link to="/">{{$t('a2')}}</router-link>
						</li>
						<li>
							<router-link to="/swap" :class=" double== 1 ?'actives':'' ">{{$t('a3')}}</router-link>
						</li>
					
						<li>
							<router-link to="/">{{$t('a4')}}</router-link>
						</li>
						<li>
							<router-link to="/">{{$t('a5')}}</router-link>
						</li>
						<li>
							<router-link to="/">NFT</router-link>
						</li>
						<li>
							<router-link to="/">{{$t('a6')}}</router-link>
						</li>
						<li  @click="gosy">
							<router-link to="/">{{$t('c8')}}</router-link>
						</li>
						<li >
							<router-link to="/"  @click.native.prevent="selectAddress"  v-if=" isgoin == false ">{{ethaddress }}</router-link>
								<router-link to="/"  v-else>{{ethaddress | numsla3}}</router-link>
						</li>
						<li @click="langclick">
							<router-link to="/">{{language}}</router-link>
						</li>
					</ul>
					
				</div>
			</div>
			<router-view />

			<img class="gubg" src="./assets/t1.png" alt="" />
			<!-- 	<img class="rigbgs" src="./assets/r2.png" alt="" />
			<img class="botm" src="./assets/xl.png" alt="" />
			<img class="bouye" src="./assets/r2.png" alt="" /> -->

		</div>
		

	<!-- 	<button @click="selectAddress">
			点击测试
		</button>
		<button @click="getad">
			签名地址
		</button> -->

	</div>
</template>
<script>
	// import 'animate.css';
	import Web3 from 'web3';
	import {
		getCurrentInstance
	} from 'vue';

	export default {
		data() {
			return {
				ishow: false,
				ethaddress:'链接钱包',
				language: 'zh',
				isgoin:false,
				double: -1,
				windowWidth: null,
				Bli: [{
						img: '/assets/b.png',
						name: 'BRC-20',
						allna: 'Bitcoin Chain'
					},
					{
						img: '/assets/j.png',
						name: 'ERC-20',
						allna: 'Ethereum'
					},
					{
						img: '/assets/y.png',
						name: 'ARC-20',
						allna: 'Bitcoin Chain'
					}
				]
			}
		},
		 watch: {
			 // 监听路由
		    $route(to, from) {
		      // 当路由变化时执行的逻辑
		      // console.log('Route changed:', to, 'from', from);
			 //  console.log(to)
			 // console.log(to.fullPath)
			  if(to.fullPath =='/swap'){
				  // console.log('swap页面')
				  this.double = 1
			  }else{
				  this.double = -1
			  }
		
		      // // 重新加载页面数据、更新视图等操作
		      // this.loadData();
		    },
			},
		mounted() {
			this.windowWidth = window.innerWidth;
			window.addEventListener('resize', this.handleResize);
		this.language = localStorage.getItem("locale") || 'zh';
		this.$i18n.locale = this.language
		// console.log(this.language)
		const lang = this.$i18n.locale
		this.ethaddress = this.$i18n.messages[lang].a7;

		},
		filters:{
		numsla3(str) {
			if (str != '') {
				let a = str.substring(0, 2)
				let b = str.substring(str.length - 6)
				// console.log(str.substring(0,4))
				// console.log(str.substring(str.length-4))
				return a + '****' + b
			} else {
				return 0
			}
		},	
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			langclick() {
			
				if (this.language == 'zh') {
					this.language = 'en'
					// console.log(this.language)
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.ethaddress = this.$i18n.messages[lang].a7;
					// this.allheder[0].name = this.$i18n.messages[lang].list[0];
					// this.allheder[1].name = this.$i18n.messages[lang].tems[0];
					// this.allheder[2].name = this.$i18n.messages[lang].list[3];
					// this.allheder[3].name = this.$i18n.messages[lang].list[4];
					// this.allheder[4].name = this.$i18n.messages[lang].list[2];
					// this.allheder[5].name = this.$i18n.messages[lang].scend[0];
					return;
				}
				if (this.language == 'en') {
					this.language = 'zh'
					// console.log(this.language)
					localStorage.setItem("locale", this.language);
					this.$i18n.locale = this.language
					const lang = this.$i18n.locale
					this.ethaddress = this.$i18n.messages[lang].a7;
					// this.allheder[0].name = this.$i18n.messages[lang].list[0];
					// this.allheder[1].name = this.$i18n.messages[lang].tems[0];
					// this.allheder[2].name = this.$i18n.messages[lang].list[3];
					// this.allheder[3].name = this.$i18n.messages[lang].list[4];
					// this.allheder[4].name = this.$i18n.messages[lang].list[2];
					// this.allheder[5].name = this.$i18n.messages[lang].scend[0];
					return
				}
			
			},
			
			chagetop(i) {
				console.log(i)
				if (i == 1) {
					this.double = i;
				} else {
					this.double = -1;
				}

				// if (i == 6) {
				// 	this.selectAddress()
				// }

				// console.log(this.double)
			},
			
			
			handleResize() {
				this.windowWidth = window.innerWidth;
				// console.log(this.windowWidth)

				if (this.windowWidth > 767) {
					this.ishow = false
				}

			},
			chageishow() {
				this.ishow = !this.ishow
			},


			async getCurrentAccountAddress() {
				const accounts = await window.ethereum.request({
					method: 'eth_accounts'
				});
				if (accounts.length > 0) {
					return accounts[0];
				} else {
					// console.log('失败')
				}
			},

			getad() {
				// 获取当前选定账户地址
				this.getCurrentAccountAddress().then(console.log);
			},

gosy(){
   var roUrls = 'https://sun-protocol.gitbook.io/'
   window.open(roUrls, '_blank');	
},


			async connectWallet() {
				try {
					// 请求用户解锁账户并授权
					await window.ethereum.request({
						method: 'eth_requestAccounts',
					});

					// 连接成功后，web3实例现在指向用户的默认账户
					console.log(web3.currentProvider.selectedAddress);
				} catch (error) {
					console.error("无法连接到MetaMask，请确保已安装并解锁钱包");
				}
			},



			async signMessage(message) {
				try {
					// 获取当前选定的账户地址
					const accounts = await window.ethereum.request({
						method: 'eth_accounts'
					});
					const account = accounts[0];
					console.log(account)

					// 调用个人_sign方法签名消息
					const signature = await window.ethereum.request({
						method: 'personal_sign',
						params: [message, account],
					});
					// console.log('链接成功')
					this.ethaddress = account
					this.isgoin = true
					return signature;
				} catch (error) {
					// console.log('链接失败')
					// console.error('Error signing message:', error);
				}
			},



			selectAddress() {

				const myMessage = 'Sun.ex';
				this.signMessage(myMessage).then(console.log);

				this.connectWallet();

				// let that = this;

				// // console.log(value)
				// //获取钱包地址  没有弹框
				// ethereum
				// 	.request({
				// 		method: 'eth_requestAccounts'
				// 	})
				// 	.then(res => {
				// 		console.log(res[0])


				// 	})
				// 	.catch((err) => {
				// 		if (err.code === 4001) {
				// 			// EIP-1193 userRejectedRequest error
				// 			// If this happens, the user rejected the connection request.账号拒绝登录metamask
				// 			// console.log('Please connect to MetaMask.');
				// 			$('#status').html('You refused to connect Metamask')
				// 		} else {
				// 			// console.log('555')
				// 			console.error(err);
				// 		}
				// 	});
			},




			change(i) {
				console.log(i)
			},
			temegarm() {
				var roUrl = 'https://url1024.com/sun_godde'
				window.open(roUrl, '_blank');
			},
			mediu() {
				var roUrls = 'https://medium.com/@CoinGPT_Web3'
				window.open(roUrls, '_blank');
			},
			tuirt(rorl) {
				window.open(rorl, '_blank');
			},

		}
	}
</script>

<style lang="scss">

	.actives {
		color: #FCBD32 !important;
	}

	.gubg {
		z-index: -999;
	}

	/* 隐藏滚动条，但保留滚动功能 */
	body::-webkit-scrollbar {
		width: 0;
		/* 隐藏水平滚动条 */
		height: 0;
		/* 隐藏垂直滚动条 */
	}

	.goyet {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hsd {
		width: 400px;
		height: 370px;
	}

	.act1 {
		background: #BEC2D1 !important;
	}

	.act2 {
		color: #000000 !important;
	}

	body {
		overflow-y: auto;
		/* 启用垂直滚动，仅当内容超出时显示滚动条 */
		overflow-x: hidden;
		/* 隐藏水平滚动条 */
	}

	.tonbur {
		margin: 50px 0;
	}

	.twite {
		cursor: pointer;
	}

	@media only screen and (min-width: 1200px) {
		li{
			// flex-shrink: 0;
		padding: 0 8px;
			flex: 1;
			text-align: center;
			a{
				width: 100%;
				display: block;
			}
		}
		.tels {
			width: 50%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 40px;
				height: 40px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			position: absolute;
			right: 0px;
			bottom: -44px;
			width: 458px;
			height: 707px;
		}

		.four {
			margin: 100px 0 100px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.zrone {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			img {
				width: 180px;
				height: 180px;
			}

			.nam {
				margin: 30px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}

		.rigbgs {
			position: absolute;
			right: 0px;
			top: 340px;
			width: 450px;
			height: 650px;
			z-index: -999;
			// bottom: 120px;
		}

		.botm {
			position: absolute;
			left: 0;
			top: 1350px;
			width: 350px;
			height: 550px;
		}


		.header {
			width: 100%;
			position: relative;
		}

		.gubg {

			position: absolute;
			top: 0;
			left: 0;
			width: 450px;
			height: 500px;
		}

		.baner {
			margin: 70px 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.baner2 {
			margin: 90px 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.sunlece {
			margin-right: 130px;
			height: 307px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				width: 400px;
				height: 50px;
			}

		

		}



		.rigcen {
			width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.kimg {
			width: 502px;
			height: 506px;
		}

		.rigb {}

		.menys {
			display: none;
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:nth-last-child(2) {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				
				// height: 28px;
				font-size: 12px !important;
				padding: 10px 10px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 75%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}


		.cont a {
			color: #fff;
			font-size: 14px;
			padding: 4px 10px;
			cursor: pointer;
		}

		.conter {
			width: 80%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;

			// background: #030706;

		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.cont>ul {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;


		}

		.left {
			width:20%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

		}

	}

	@media screen and (min-width:768px) and (max-width:1200px) {
		
		li{
			// flex-shrink: 0;
		// padding: 0 15px;
			flex: 1;
			text-align: center;
			a{
				width: 100%;
				display: block;
			}
		}
		.tels {
			width: 50%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}
		
		
		.tels {
			width: 50%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 40px;
				height: 40px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			position: absolute;
			right: 0px;
			bottom: -30px;
			width: 358px;
			height: 527px;
		}

		.four {
			width: 100%;
			margin: 50px 0 100px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.zrone {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			img {
				width: 150px;
				height: 150px;
			}

			.nam {
				margin: 20px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}


		.botm {
			position: absolute;
			left: 0;
			top: 1000px;
			width: 330px;
			height: 500px;
		}


		.baner2 {
			margin: 30px 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}


		.rigbgs {
			position: absolute;
			right: 0px;
			top: 300px;
			width: 350px;
			height: 550px;
			z-index: -999;
			// bottom: 120px;
		}




		.header {
			width: 100%;
			position: relative;
		}

		.gubg {

			position: absolute;
			top: 0;
			left: 0;
			width: 349px;
			height: 396px;
		}

		.baner {
			margin: 70px 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.sunlece {
			height: 307px;
			display: flex;
			flex-direction: column;

			justify-content: center;

			img {
				align-self: flex-start;
				width: 281px;
				height: 35px;
			}

			.order {
				margin: 40px 0;
				width: 307px;
				// height: 52px;

				font-weight: 600;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 1.5;

			}

		}

		.rigcen {
			// width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.rigb {
			img {
				width: 311px;
				height: 307px;
			}
		}



		.menys {
			display: none;
		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:nth-last-child(2) {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				// height: 28px;
				font-size: 12px !important;
				padding: 6px 5px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 75%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.header {
			width: 100%;
		}

		.cont a {
			color: #fff;
			font-size: 14px;

			cursor: pointer;
		}

		body {
			overflow-x: hidden;
		}

		.conter {
			width: 70%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			// background: #030706;

		}

		.cont>ul {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;


		}

		.left {
			width: 20%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

		}


	}

	//当屏幕最大宽度为767px 
	@media screen and (max-width:767px) {
		.tels {
			width: 80%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 30px;
				height: 30px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			display: none;
		}

		.four {
			width: 100%;
			margin: 40px 0 10px 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.zrone {
			width: 45%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 40px;

			img {
				width: 50%;
				// height: 180px;
			}

			.nam {
				margin: 30px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}

		.botm {
			display: none;
		}

	

		.baner2 {
			// width: 90%;
			// margin: 30px 0 30px;
			// display: flex;
			// align-items: center;
			// flex-direction: column;
			// justify-content: space-between;

			width: 90%;

			margin: 20px auto 30px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.rigbgs {
			display: none;
		}



		.header {
			width: 100%;
			position: relative;
		}

		.gubg {
			position: absolute;
			top: 0;
			left: 0;
			width: 349px;
			height: 396px;
		}

		.baner {
			// background-color: #ccc !important;
			width: 90%;

			margin: 20px auto 10px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.sunlece {
			width: 100%;
			// height: 307px;
			display: flex;
			flex-direction: column;

			justify-content: center;

			img {
				align-self: flex-start;
				width: 281px;
				height: 35px;
			}

			.order {
				margin: 30px 0;
				width: 90%;
				font-weight: 600;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 1.5;

			}

		}

		.rigcen {
			// width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 120px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 120px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.rigb {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 311px;
				height: 307px;
				margin: 50px 0 0;
			}
		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ishoe {
			display: none;
		}

		.menys {
			z-index: 999 !important;

			img {
				z-index: 999 !important;
				width: 40px;
				height: 40px;
			}
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:nth-last-child(2) {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				height: 28px;
				font-size: 12px !important;
				padding: 6px 20px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 100%;

			// display: flex;
			// align-items: center;
			// justify-content: space-between;
		}

		.header {
			width: 100%;
		}

		.cont a {
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}

		.conter {
			width: 90%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			// background: #030706;

		}

		.cont>ul {
			width: 100%;
			margin-top: -5px;
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;


		}

		li {
			padding: 5px 0;
		}

		.left {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;

		}

	}

	.logins {
		width: 90px;
		height: 28px;
		font-size: 9px;
		margin: 0 0 0 50px;
		text-align: center;
		line-height: 28px;
		background: #FCBD32;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #FCBD32;
	}








	/* 清除链接默认样式 */
	a {
		text-decoration: none;
		color: inherit;

	}

	/* 清除列表默认样式 */
	ul,
	ol {
		list-style: none;
	}

	/* 清除图片默认边框 */
	img {
		border: none;
	}


	.logo {
		width: 146px;
		height: 18px;
	}

	body {
		background: #0E0E14;

	}
</style>