<template>
	<div>
		<div class="header">
			<div class="conter ">
				<div class="baner">
					<div class="sunlece">
						<img src="../assets/ba.png" alt="" />
						<div class="order">
							<!-- 该平台整合了多链交易，跨链，质押，闪电网络和其他领域流动性，为用户提供了更加便捷安全，深入全球资产的交易生态系统。 -->
						{{$t('a8')}}
						</div>
						<div class="rigcen">
							<div class="oney">
								{{$t('a9')}}
							</div>
							<div class="oney1">
								{{$t('b1')}}
							</div>
						</div>
					</div>
					<div class="rigb">
						<img class="kimg" src="../assets/12.png" alt="" />
					</div>

				</div>
			</div>
			<div class="conter">
				<div class="mains">
					<div class="matop">
						<div class="ony " v-for="(item,index) in Bli" :key="index" :class=" index ==0?'act1':'' ">
							<img v-if=" index ==0 " src="../assets/b.png" alt="" />
							<img v-if=" index ==1 " src="../assets/j.png" alt="" />
							<img v-if=" index ==2 " src="../assets/y.png" alt="" />
							<div class="tobo">
								<div class="fot" :class=" index ==0?'act2':'' ">
									{{item.name}}
								</div>
								<div class="nore" :class=" index ==0?'act2':'' ">{{item.allna}}</div>
							</div>
						</div>
					</div>
					<div class="imhu">
						<img v-for="(item,index) in 6" :key="index" src="../assets/11.png" alt="" />
					</div>
				</div>
			</div>

			<div class="conter">
				<div class="baner2 tonbur">
					<div class="sunlece">
						<img src="../assets/ba.png" alt="" />
						<div class="order2">
							{{$t('b2')}} </div>
						<div class="hhh">

						</div>
					</div>
					<div class="rigb">
						<img class="hsd" src="../assets/1.png" alt="" />
					</div>

				</div>
			</div>

			<div class="conter">
				<div class="four">
					<div class="zrone">
						<img src="../assets/01.png" alt="" />
						<div class="nam">{{$t('b3')}}</div>
						<div class="jis">
							{{$t('b4')}}
						</div>
					</div>
					<div class="zrone">
						<img src="../assets/04.png" alt="" />
						<div class="nam">{{$t('b5')}}</div>
						<div class="jis">
							{{$t('b6')}}
						</div>
					</div>
					<div class="zrone">
						<img src="../assets/02.png" alt="" />
						<div class="nam">{{$t('b7')}}</div>
						<div class="jis">
							{{$t('b8')}}
						</div>
					</div>
					<div class="zrone">
						<img src="../assets/03.png" alt="" />
						<div class="nam">{{$t('b9')}}</div>
						<div class="jis">
							{{$t('c1')}}
						</div>
					</div>
				</div>
			</div>

			<div class="conter">
				<div class="bor"></div>
			</div>
			
			<div class="conter">
				<div class="goyet">
					<div class="tels">
						<div class="twite" @click="temegarm">
							<img src="../assets/Frame@2x.png" alt="" />
							<div class="gar">Telegram</div>
						</div>
						<div class="twite"  @click="tuite">
							<img src="../assets/X@2x.png" alt="" />
							<div class="gar">Twitter</div>
						</div>
						<div class="twite" @click="godc">
							<img src="../assets/dc.webp" alt="" />
							<div class="gar">DC</div>
						</div>
				
					</div>
				</div>
			</div>
			<div class="conter">
				<div class="twite" @click="sun">
					<!-- <img class="ius" src="../assets/ba.png" alt="" /> -->
					<div class="gar">{{$t('c2')}} : https://sun-protocol.gitbook.io/</div>
				</div>
			</div>
		</div>


		<!-- <img class="gubg" src="../assets/t1.png" alt="" /> -->
		<img class="rigbgs" src="../assets/r2.png" alt="" />
		<img class="botm" src="../assets/xl.png" alt="" />
		<img class="bouye" src="../assets/r2.png" alt="" />
	</div>
</template>

<script>
	import 'animate.css';
	import Web3 from 'web3';
	import {
		getCurrentInstance
	} from 'vue';

	export default {
		data() {
			return {
				ishow: false,
				language: 'zh',
				allheder: [{
						name: '首页',
						path: '/'
					},
					{
						name: 'SWAP',
						path: '/swap'
					},
					{
						name: '跨链',
						path: '/'
					},
					{
						name: '质押',
						path: '/'
					},
					{
						name: 'NFT',
						path: '/'
					},
					{
						name: '关于我们',
						path: '/'
					},
					{
						name: '链接钱包',
						path: '/'
					}
				],
				double: 0,
				windowWidth: null,
				Bli: [{
						img: '/assets/b.png',
						name: 'BRC-20',
						allna: 'Bitcoin Chain'
					},
					{
						img: '/assets/j.png',
						name: 'ERC-20',
						allna: 'Ethereum'
					},
					{
						img: '/assets/y.png',
						name: 'ARC-20',
						allna: 'Bitcoin Chain'
					}
				]
			}
		},
		mounted() {
			this.windowWidth = window.innerWidth;
			window.addEventListener('resize', this.handleResize);

		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			handleResize() {
				this.windowWidth = window.innerWidth;
				// console.log(this.windowWidth)

				if (this.windowWidth > 767) {
					this.ishow = false
				}

			},
			chageishow() {
				this.ishow = !this.ishow
			},


			async getCurrentAccountAddress() {
				const accounts = await window.ethereum.request({
					method: 'eth_accounts'
				});
				if (accounts.length > 0) {
					return accounts[0];
				} else {
					throw new Error('No Ethereum account selected.');
				}
			},

			getad() {
				// 获取当前选定账户地址
				this.getCurrentAccountAddress().then(console.log);
			},



			async connectWallet() {
				try {
					// 请求用户解锁账户并授权
					await window.ethereum.request({
						method: 'eth_requestAccounts',
					});

					// 连接成功后，web3实例现在指向用户的默认账户
					console.log(web3.currentProvider.selectedAddress);
				} catch (error) {
					console.error("无法连接到MetaMask，请确保已安装并解锁钱包");
				}
			},



			async signMessage(message) {
				try {
					// 获取当前选定的账户地址
					const accounts = await window.ethereum.request({
						method: 'eth_accounts'
					});
					const account = accounts[0];
					console.log(account)

					// 调用个人_sign方法签名消息
					const signature = await window.ethereum.request({
						method: 'personal_sign',
						params: [message, account],
					});

					return signature;
				} catch (error) {
					console.error('Error signing message:', error);
				}
			},



			selectAddress() {

				const myMessage = 'Hello, MetaMask!';
				this.signMessage(myMessage).then(console.log);

				// this.connectWallet();

				// let that = this;

				// // console.log(value)
				// //获取钱包地址  没有弹框
				// ethereum
				// 	.request({
				// 		method: 'eth_requestAccounts'
				// 	})
				// 	.then(res => {
				// 		console.log(res[0])


				// 	})
				// 	.catch((err) => {
				// 		if (err.code === 4001) {
				// 			// EIP-1193 userRejectedRequest error
				// 			// If this happens, the user rejected the connection request.账号拒绝登录metamask
				// 			// console.log('Please connect to MetaMask.');
				// 			$('#status').html('You refused to connect Metamask')
				// 		} else {
				// 			// console.log('555')
				// 			console.error(err);
				// 		}
				// 	});
			},




			change(i) {
				console.log(i)
			},
			temegarm() {
				var roUrl = 'https://url1024.com/sun_godde'
				window.open(roUrl, '_blank');
			},
			tuite(){
			
			var roUrl = 'https://x.com/godde_sun/'
			window.open(roUrl, '_blank');
			},
			godc(){
			  	var roUrl = 'https://discord.gg/Vjks4xe7'
			  	window.open(roUrl, '_blank');
			},
			mediu() {
				var roUrls = 'https://medium.com/@CoinGPT_Web3'
				window.open(roUrls, '_blank');
			},
			sun() {
				var roUrls = 'https://sun-protocol.gitbook.io/'
				window.open(roUrls, '_blank');
			},
			tuirt(rorl) {
				window.open(rorl, '_blank');
			},

		}
	}
</script>

<style lang="scss" scoped>
	.ius{
		width: 80px !important;
		height: 16px !important;
	}
	.goyet {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hsd {
		width: 400px;
		height: 370px;
	}

	.act1 {
		background: #BEC2D1 !important;
	}

	.act2 {
		color: #000000 !important;
	}

	body {
		overflow-y: auto;
		/* 启用垂直滚动，仅当内容超出时显示滚动条 */
		overflow-x: hidden;
		/* 隐藏水平滚动条 */
	}

	.tonbur {
		margin: 50px 0;
	}

	.twite {
		cursor: pointer;
	}

	@media only screen and (min-width: 1200px) {
		.tels {
			width: 70%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 40px;
				height: 40px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			position: absolute;
			right: 0px;
			bottom: -44px;
			width: 458px;
			height: 707px;
		}

		.four {
			margin: 100px 0 100px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.zrone {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			img {
				width: 180px;
				height: 180px;
			}

			.nam {
				margin: 30px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}

		.rigbgs {
			position: absolute;
			right: 0px;
			top: 340px;
			width: 450px;
			height: 650px;
			z-index: -999;
			// bottom: 120px;
		}

		.botm {
			position: absolute;
			left: 0;
			top: 1350px;
			width: 350px;
			height: 550px;
		}

		.mains {
			width: 100%;
			margin: 80px auto;
			// width: 760px;
			// height: 548px;
			background: #0E0E14;
			border-radius: 17px 17px 17px 17px;
			border: 2px solid #292A38;

		}

		.imhu {
			margin-top: 80px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				width: 28%;
				// height: 340px;
				margin-bottom: 30px;
			}
		}

		.matop {
			margin: -30px auto;
			width: 507px;
			height: 65px;
			background: #282939;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.ony {
			width: 150px;
			height: 55px;
			background: #4B4C60;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 32px;
				height: 32px;
				margin-right: 5px;
			}

			.tobo {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}

			.fot {

				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #fff;
				padding-bottom: 3px;
				line-height: 15px;
				// text-align: center;
			}

			.nore {

				font-weight: 400;
				font-size: 11px;
				color: #fff;
				line-height: 13px;
			}
		}

		.header {
			width: 100%;
			position: relative;
		}

		.gubg {

			position: absolute;
			top: 0;
			left: 0;
			width: 500px;
			height: 530px;
		}

		.baner {
			margin: 70px 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.baner2 {
			margin: 90px 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.sunlece {
			margin-right: 130px;
			height: 307px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				width: 400px;
				height: 50px;
			}

			.order {
				margin: 40px 0;
				width: 400px;
				// height: 52px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 1.5;
				text-align: left;
				font-style: normal;
				text-transform: none;
			}

		}

		.order2 {
			margin: 60px 0 30px;
			width: 400px;
			height: 52px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 1.5;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}


		.rigcen {
			width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.kimg {
			width: 502px;
			height: 506px;
		}

		.rigb {}

		.menys {
			display: none;
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:last-child {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				height: 28px;
				font-size: 12px !important;
				padding: 6px 20px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 75%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}


		.cont a {
			color: #fff;
			font-size: 16px;
			padding: 4px 10px;
			cursor: pointer;
		}

		.conter {
			width: 70%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;

			// background: #030706;

		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.cont>ul {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;


		}

		.left {
			width: 30%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

		}

	}

	@media screen and (min-width:768px) and (max-width:1200px) {
		.tels {
			width: 100%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 40px;
				height: 40px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			position: absolute;
			right: 0px;
			bottom: -30px;
			width: 358px;
			height: 527px;
		}

		.four {
			width: 100%;
			margin: 50px 0 100px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.zrone {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			img {
				width: 150px;
				height: 150px;
			}

			.nam {
				margin: 20px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}


		.botm {
			position: absolute;
			left: 0;
			top: 1000px;
			width: 330px;
			height: 500px;
		}

		.order2 {
			margin: 60px 0 30px;
			width: 80%;
			height: 52px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 1.5;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}

		.baner2 {
			margin: 30px 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}


		.rigbgs {
			position: absolute;
			right: 0px;
			top: 300px;
			width: 350px;
			height: 550px;
			z-index: -999;
			// bottom: 120px;
		}

		.mains {
			width: 100%;
			margin: 80px auto 50px;
			// width: 760px;
			// height: 548px;
			background: #0E0E14;
			border-radius: 17px 17px 17px 17px;
			border: 2px solid #292A38;

		}

		.imhu {
			margin-top: 80px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				width: 210px;
				height: 210px;
				margin-bottom: 30px;
			}
		}

		.matop {
			margin: -30px auto;
			width: 470px;
			height: 55px;
			background: #282939;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.ony {
			width: 150px;
			height: 50px;
			background: #4B4C60;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 32px;
				height: 32px;
				margin-right: 5px;
			}

			.tobo {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}

			.fot {

				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #fff;
				padding-bottom: 3px;
				line-height: 15px;
				// text-align: center;
			}

			.nore {

				font-weight: 400;
				font-size: 11px;
				color: #fff;
				line-height: 13px;
			}
		}



		.header {
			width: 100%;
			position: relative;
		}

		.gubg {

			position: absolute;
			top: 0;
			left: 0;
			width: 349px;
			height: 396px;
		}

		.baner {
			margin: 70px 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.sunlece {
			height: 307px;
			display: flex;
			flex-direction: column;

			justify-content: center;

			img {
				align-self: flex-start;
				width: 281px;
				height: 35px;
			}

			.order {
				margin: 40px 0;
				width: 307px;
				height: 52px;

				font-weight: 600;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 1.5;

			}

		}

		.rigcen {
			// width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 130px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.rigb {
			img {
				width: 311px;
				height: 307px;
			}
		}



		.menys {
			display: none;
		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:last-child {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				height: 28px;
				font-size: 12px !important;
				padding: 6px 20px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 75%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.header {
			width: 100%;
		}

		.cont a {
			color: #fff;
			font-size: 14px;

			cursor: pointer;
		}

		body {
			overflow-x: hidden;
		}

		.conter {
			width: 70%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			// background: #030706;

		}

		.cont>ul {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;


		}

		.left {
			width: 30%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: flex-start;

		}


	}

	//当屏幕最大宽度为767px 
	@media screen and (max-width:767px) {
		.tels {
			width: 80%;
			height: 100px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-around;
		}

		.twite {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 30px;
				height: 30px;
				margin-right: 16px;
			}

			.gar {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FFFFFF;
				line-height: 15px;
			}
		}

		.bor {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

		.bouye {
			display: none;
		}

		.four {
			width: 100%;
			margin: 40px 0 10px 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.zrone {
			width: 45%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 40px;

			img {
				width: 50%;
				// height: 180px;
			}

			.nam {
				margin: 30px 0;
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 17px;
				color: #FFFFFF;
				line-height: 20px;
			}

			.jis {
				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #FCBD32;
				line-height: 15px;
			}
		}

		.botm {
			display: none;
		}

		.order2 {
			margin: 60px 0 30px;
			width: 90%;
			height: 52px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 1.5;
			text-align: left;
			font-style: normal;
			text-transform: none;
		}

		.baner2 {
			// width: 90%;
			// margin: 30px 0 30px;
			// display: flex;
			// align-items: center;
			// flex-direction: column;
			// justify-content: space-between;

			width: 90%;

			margin: 20px auto 30px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.rigbgs {
			display: none;
		}

		.mains {
			width: 90%;
			margin: 80px auto;
			// width: 760px;
			// height: 548px;
			background: #0E0E14;
			border-radius: 17px 17px 17px 17px;
			border: 2px solid #292A38;

		}

		.imhu {
			margin-top: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			img {
				width: 130px;
				height: 130px;
				margin-bottom: 30px;
			}
		}

		.matop {
			margin: -30px auto;
			width: 100%;
			height: 55px;
			background: #282939;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		.ony {
			width: 30%;
			height: 40px;
			background: #4B4C60;
			border-radius: 28px 28px 28px 28px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 24px;
				height: 24px;
				margin-right: 5px;
			}

			.tobo {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}

			.fot {

				font-family: PingFang SC, PingFang SC;
				font-weight: 600;
				font-size: 12px;
				color: #fff;
				padding-bottom: 3px;
				line-height: 15px;
				// text-align: center;
			}

			.nore {

				font-weight: 400;
				font-size: 11px;
				color: #fff;
				line-height: 13px;
			}
		}

		.header {
			width: 100%;
			position: relative;
		}

		.gubg {
			position: absolute;
			top: 0;
			left: 0;
			width: 349px;
			height: 396px;
		}

		.baner {
			// background-color: #ccc !important;
			width: 90%;

			margin: 20px auto 10px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.sunlece {
			width: 100%;
			// height: 307px;
			display: flex;
			flex-direction: column;

			justify-content: center;

			img {
				align-self: flex-start;
				width: 281px;
				height: 35px;
			}

			.order {
				margin: 30px 0;
				width: 90%;
				font-weight: 600;
				font-size: 14px;
				color: #FFFFFF;
				line-height: 1.5;

			}

		}

		.rigcen {
			// width: 400px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.oney {
			width: 120px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #FCBD32;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;


		}

		.oney1 {
			margin-left: 20px;
			width: 120px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #bec2d1;
			border-radius: 6px 6px 6px 6px;

			font-family: PingFang SC, PingFang SC;
			font-weight: 600;
			font-size: 11px;
			color: #000000;
		}

		.rigb {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 311px;
				height: 307px;
				margin: 50px 0 0;
			}
		}

		.comm {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ishoe {
			display: none;
		}

		.menys {
			z-index: 999 !important;

			img {
				z-index: 999 !important;
				width: 40px;
				height: 40px;
			}
		}

		/* 选取最后一个具有 .my-class 类的元素 */
		li:last-child {

			/* 在这里添加您希望应用于最后一个元素的样式 */
			a {
				height: 28px;
				font-size: 12px !important;
				padding: 6px 20px;
				background: #FCBD32;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #FCBD32;
			}

			/* 或者其他您需要设置的样式 */
		}

		.cont {
			width: 100%;

			// display: flex;
			// align-items: center;
			// justify-content: space-between;
		}

		.header {
			width: 100%;
		}

		.cont a {
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}

		.conter {
			width: 90%;
			color: #fff;
			// border: 1px solid #ccc;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			// background: #030706;

		}

		.cont>ul {
			width: 100%;
			margin-top: -5px;
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;


		}

		li {
			padding: 5px 0;
		}

		.left {
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;

		}

	}

	.logins {
		width: 90px;
		height: 28px;
		font-size: 9px;
		margin: 0 0 0 50px;
		text-align: center;
		line-height: 28px;
		background: #FCBD32;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #FCBD32;
	}








	/* 清除链接默认样式 */
	a {
		text-decoration: none;
		color: inherit;

	}

	/* 清除列表默认样式 */
	ul,
	ol {
		list-style: none;
	}

	/* 清除图片默认边框 */
	img {
		border: none;
	}


	.logo {
		width: 146px;
		height: 18px;
	}

	body {
		background: #0E0E14;

	}
</style>