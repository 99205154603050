// 创建文件zh/index.js
const zh = {
	a1: '全链流动池',
	a2:'全链跨链桥',
	a3:'全链SWAP',
	a4:'全链借贷',
	a5:'单币质押',
	a6:'去中心化数字身份 Sun ID',
	a7:'链接钱包',
	a8:'该平台整合了多链交易，跨链，质押，闪电网络和其他领域流动性，为用户提供了更加便捷安全，深入全球资产的交易生态系统。',
	a9:'立即交易',
	b1:'了解更多',
	b2:'打破链上资产和信息转移的障碍，打造高弹性、高效的用户体验。',
	b3:'聚合',
	b4:'聚合多链流动性',
	b5:'聪明',
	b6:'最佳竞争定价',
	b7:'高效',
	b8:'快速交易执行',
	b9:'安全',
	c1:'安全的交易环境',
	c2:'白皮书',
	c3:'输入数量',
	c4:'剩余数量',
	c5:'概括',
	c6:'您将支付手续费',
	c7:'您将收到',
	c8:'关于我们'
}
export default zh