// 创建文件en/index.js
const en = {
	a1: 'Full-Chain Liquidity Pool',
	a2: 'Cross-Chain Bridge Across Chains',
	a3: 'Full-Chain Swap',
	a4: 'Full-Chain Lending',
	a5: 'Single-Token Staking',
	a6: 'Decentralized Digital Identity Sun ID',
	a7: 'Connect Wallet',
	a8: 'The platform integrates multi-chain trading, cross-chain, staking, lightning networks, and liquidity from various domains, offering users a more convenient and secure ecosystem for global asset transactions.',
	a9: 'Trade Now',
	b1: 'Learn More',
	b2: 'Breaking barriers for on-chain asset and information transfers, creating a highly resilient and efficient user experience.',
	b3: 'Aggregation',
	b4: 'Aggregate Multi-Chain Liquidity',
	b5: 'Smart',
	b6: 'Optimal Competitive Pricing',
	b7: 'Efficient',
	b8: 'Rapid Trade Execution',
	b9: 'Secure',
	c1: 'Secure Trading Environment',
	c2: 'Whitepaper',
	c3: 'Enter Quantity',
	c4: 'Remaining Quantity',
	c5: 'Summary',
	c6: 'You Will Pay Fees Of',
	c7: 'You Will Receive',
	c8:'About Us'
}

export default en